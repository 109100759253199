/** @format */

.page-error {
  width: 100vw;
  height: 100vh;
  background-color: $primary;
  position: fixed;
  top: 0;
  left: 0;
  color: white;
  overflow-y: scroll;

  button {
    color: white;
    text-decoration: underline;
  }

  hr {
    margin: 20px;
  }

  .icon {
    font-size: 3rem;
    margin-bottom: 30px;
  }
}
.page-error-content {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
.page-error-content h1 {
  margin-bottom: 30px;
}
.page-error-content h2 {
  margin-bottom: 10px;
}
.page-error-logo {
  position: absolute;
  top: 10%;
  left: 50%;
  height: 80vh;
  transform: translateX(-50%);
  opacity: 0.5;
}
.page-error-refresh {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
