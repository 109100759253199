/** @format */

@import 'colors';
@import 'fonts';

@import 'custom_theme'; // CUSTOMIZATION, dynamic

@import 'form';
@import 'bootstrap';
@import 'loader';
@import 'common';
@import 'login';
@import 'navbar';
@import 'dashboard';
@import 'scenarios';
@import 'graph';
@import 'error';

@import 'utils';
@import 'help';
@import 'omnibar';

// CRISP HACK
.crisp-client .cc-kv6t .cc-1xry .cc-unoo {
  bottom: 0px !important;
  right: 0px !important;
}

.crisp-client .cc-kv6t .cc-1xry .cc-unoo .cc-7doi .cc-1iv2 .cc-15mo .cc-10ml,
.crisp-client .cc-kv6t .cc-1xry .cc-unoo .cc-7doi .cc-1iv2 .cc-15mo .cc-10ml .cc-1dx3,
.crisp-client .cc-kv6t .cc-1xry .cc-unoo .cc-7doi {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 30px !important;
}
