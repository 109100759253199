.fill {
  height: 100%;
  width: 100%;
  overflow: hidden;
  overflow-y:scroll;
  margin: 0;
}

.no-margin {
  padding: 0px;
}

#leftColumn {
  padding: 0;
}
#rightColumn {
  padding: 0;
  height: 100%;
  overflow: hidden;
}
#editionContainer {
  height: 100%;
  overflow: hidden;
}