/** @format */

#login,
#consent-overflow {
  display: flex;
  min-height: 90vh;
  justify-content: center;
  align-items: center;

  .popup {
    box-shadow: $primary-box-shadow;
    -webkit-box-shadow: $primary-box-shadow;
    -moz-box-shadow: $primary-box-shadow;
    padding: 10px;
    position: relative;
    background-color: $system;
    // height: 400px;

    #logo {
      position: absolute;
      top: -30%;
      left: 50%;
      height: 100%;
      z-index: -20;
      transform: translateX(-50%);
    }

    #title {
      text-align: center;
      margin-bottom: 20px;
    }
    label {
      text-transform: uppercase;
    }
    button {
      width: 100%;
      margin-top: 20px;
    }
  }
}

#consent-overflow {
  position: relative;
  min-height: 100vh;
  background-color: $primaryAlpha;
  z-index: 5000;
  .popup {
    #logo {
      position: absolute;
      top: -120%;
    }
  }
}
