.hidden {
  display: none !important;
}

.margin-top-reducer {
  display: block;
  margin-top: -30px;
  height: 10px;
  z-index: -1;
}

.fresh-air {
  display: block;
  margin-bottom: 10px;
  height: 10px;
  z-index: -1;
}

.float-right {
  float: right;
}
