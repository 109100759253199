/** @format */

// COMMON //
$primary: #333333;
$secondary: #AE68F7;
$warning: #e0b300;
$danger: #f34848;
$system: #f3f4f6;
$primary-gradient: linear-gradient(158deg, rgba(0, 27, 72, 1) 0%, rgba(0, 27, 72, 1) 27%, rgba(9, 51, 120, 1) 100%);
$primaryAlpha: #333333ad;
$textMain: #333;
$textLight: white;
$systemBG: #f3f4f6;
$systemBG-light: #f3f4f6;

$headerColor: $textLight;
$headerColorSubmenu: $primary;
$headerColorHover: $textLight;

$primary-shadow: rgba(0, 0, 0, 0.12);
$primary-shadow-from: rgba(0, 27, 72, 0.42);
$primary-shadow-to: rgba(0, 27, 72, 0.2);
$primary-box-shadow: 0 14px 26px -12px $primary-shadow-from, 0 4px 23px 0px $primary-shadow,
  0 8px 10px -5px $primary-shadow-to !important;
$primary-box-shadow-small: 0 3px 6px -3px $primary-shadow-from, 0 1px 5px 0px $primary-shadow,
  0 2px 2px -1px $primary-shadow-to !important;
// COMMON COLORS LIBS //

// CONTAINERS //

// END CONTAINERS //

// GRAPH //

// END GRAPH //

// FORMS //

// END FORMS //

/* ==========================================================================
1. State colors
========================================================================== */

$red: #ff7273;
$blue: #039be5;
$green: #00b289;
$orange: #eda514;

/* ==========================================================================
2. Social colors
========================================================================== */

$facebook: #3b5998;
$twitter: #1dcaff;
$linkedin: #007bb6;
$tumblr: #35465c;
$github: #444;
$dribbble: #ea4c89;
$google-plus: #d34836;
$youtube: #cc181e;
$reddit: #ff4500;

// END COMMON COLORS LIBS //
