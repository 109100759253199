@font-face {
  font-family: "Barlow";
  src: url("../../../../assets/Barlowregular.ttf");
}
@font-face {
  font-family: "Audiowide";
  src: url("../../../../assets/Audiowideregular.ttf");
}
@font-face {
  font-family: "Ooredoo";
  src: url("../../../../assets/Ooredoo-Heavy.otf");
}
.side-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100vh;
  width: 200px;
  text-align: left;
  color: #fff;
  z-index: 999;
  overflow-y: auto;
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
  -webkit-box-shadow: 9px 0px 25px -4px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 9px 0px 25px -4px rgba(0, 0, 0, 0.04);
  box-shadow: 9px 0px 25px -4px rgba(0, 0, 0, 0.04);
  overflow: hidden;
}
.sidebarSection {
  font-family: "Audiowide";
  font-size: 0.8em;
  color: #333;
}

.sideBar-button {
  border: transparent;
  background: transparent;
  font-family: "Audiowide";
  font-style: normal;
  font-size: 0.6em;
  color: #333;
}
.navbar {
  height: 60px !important;
  position: fixed !important;
  width: 100%;
  z-index: 999;
}
.sideLogo {
  width: 50%;
}
.side-navbar ul {
  padding: 0;
  width: 100%;
}
.side-navbar li {
  text-decoration: none;
  list-style: none;
}
.side-navbar.shrink {
  width: 100px;
  text-align: center;
}
.widthLogo {
  width: 50% !important;
}
.rotate {
  transform: rotate(180deg);
}
.newitem-icon p {
  display: none;
}

.sideBar-button:before {
  font-weight: 900;
  font-family: "FontAwesome";
  font-size: 25px;
  color: rgb(0, 27, 72);
}
.tab-button:before {
  font-weight: 900;
  font-family: "FontAwesome";
  font-size: 12px;
  color: rgb(0, 27, 72);
  margin-right: 2px;
}
.npcs-icon:before {
  content: "\f0c0";
}

.editor-icon:before {
  content: "\f040";
}
.header-icon:before {
  content: "\f2c2";
}
.trads-icon:before {
  content: "\f0ac";
}
.export-icon:before {
  content: "\f093";
}
.inventory-icon:before {
  content: "\f187";
}
.add-icon:before {
  content: "\f0fe";
}
.files-icon:before {
  content: "\f093";
}
.home-icon:before {
  content: "\f015";
}
.map-icon:before {
  content: "\f59f";
}
.chat-icon:before {
  content: "\f086";
}
.diagram-icon:before {
  content: "\f542";
}
.stats-icon:before {
  content: "\f201";
}
.admin-icon:before {
  content: "\f084";
}
.ce-icon:before {
  content: "\f0b1";
}
.codes-icon:before {
  content: "\f13e";
}
.applications-icon:before {
  content: "\f10a";
}
.users-icon:before {
  content: "\f0c0";
}
.tools-icon:before {
  content: "\f0ad";
}
.releaseInfo-icon:before {
  content: "\f187";
}
.meta-icon:before {
  content: "\f05a";
}
.import-icon:before {
  content: "\f019";
}
.new-icon:before {
  content: "\f055";
}
.linkStyle {
  font-family: Barlow;
  margin-bottom: 1px;
  width: 100%;
  background: transparent;
  text-decoration: none;
  display: inline-block;
  border: none;
  padding: 10px;
  transition: all 0.6s ease 0s;
}
.linkStyle:focus {
  background: #AE68F7;
  outline: 0;
}
.linkStyle:hover {
  outline: 0;
  cursor: pointer;
  text-decoration: none;
  link-style: none;
  background: #AE68F7;
  letter-spacing: 1px;
  transition: all 0.3s ease 0s;
}
.settings-icon:before {
  content: "\f085";
}
@media (max-width: 1199px) {
  .side-navbar {
    left: 0;
    width: 70px;
    text-align: center;
  }

  .side-navbar.show-sm {
    left: 0 !important;
  }

  .side-navbar .brand-small {
    display: block;
  }
  .side-navbar .sidenav-heading {
    margin-left: 0;
    font-size: 0.8rem;
  }
  .side-navbar i {
    display: block;
    margin-right: 0;
    font-size: 1rem;
  }
  .titleSide {
    display: none;
  }
}
