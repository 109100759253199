/** @format */

#graphContainer {
  padding: 0px;
  z-index: 1050;

  .graph-alert {
    width: calc(100% - 200px);
    right: 0px;
    left: 200px;
  }

  &.fill {
    overflow-y: hidden !important;
  }

  div {
    // FIX FOR MULTIPLE SCROLLBAR
    &.fill {
      overflow-y: hidden !important;
    }
  }

  .floating-bar {
    position: absolute;
    display: flex;
    bottom: 2%;

    .zoom-button {
      background-color: $primaryAlpha;
      box-shadow: $primary-box-shadow;
      -webkit-box-shadow: $primary-box-shadow;
      -moz-box-shadow: $primary-box-shadow;
      border-radius: 5px;
      padding: 5px 10px;
      margin-right: 5px;
      height: 100%;
      display: inline-block;
      text-align: center;
      color: $textLight;
      cursor: pointer;
      border: 0px;
    }

    .help {
      .btn,
      .btn-secondary {
        border: 0px;
        background-color: $primaryAlpha;
        box-shadow: $primary-box-shadow;
        -webkit-box-shadow: $primary-box-shadow;
        -moz-box-shadow: $primary-box-shadow;
        border-radius: 5px;
        padding: 5px 10px;
        margin-right: 10px;
        height: 100%;
        display: inline-block;
        display: none;
        text-align: center;
        color: $textLight;
        cursor: pointer;
        border: 0px;
        font-size: 0.7em;
        letter-spacing: 2px;
      }
    }
  }

  #widgets {
    position: absolute;
    top: 0px;
    padding: 0px;
    left: 0px;
    z-index: 10;
    bottom: 0px;
    padding: 0px;
    margin: 0px;
    max-width: 250px;


    .form-group {
      margin-right: 0px;
      margin-top: 5px;
      width: 100%;

      select {
        height: 100%;
        background-color: rgba(0, 0, 0, 0);
        color: white;
        border: 0;
        font-size: 0.7em;
        letter-spacing: 1;
      }
    }

    .widget-container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      background-color: none;
      overflow-x: hidden;
      overflow-y: scroll;
      background-color: white;
      padding: 10px 20px 10px 20px;

      .category {
        color: $primary;
        text-align: left;
        display: inline-block;
        letter-spacing: 2px;
        font-size: 1.1em;
        font-weight: bold;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        cursor: default;
      }

      .widget-button {
        background-color: none;
        text-align: left;
        padding: 5px;
        width: 100%;
        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab !important;
        cursor: -moz-grab !important;
        cursor: -webkit-grab !important;
        margin-bottom: 5px;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: bold;
        font-size: 1.4em;

        .icon {
          width: 20%;
          display: inline-block;
          justify-content: center;
          padding-top: 3px;
          padding-bottom: 3px;

          &:before {
            display: block;
          }
        }

        label {
          max-width: 75%;
          color: $textLight;
          font-size: 0.7em;
          letter-spacing: 1px;
          margin: 0px;
          cursor: move; /* fallback if grab cursor is unsupported */
          cursor: grab !important;
          cursor: -moz-grab !important;
          cursor: -webkit-grab !important;
        }
      }
    }
  }
}
