/** @format */

#dashboard {
  .fill {
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dashboard-option {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    margin: 0 10px;

    .logo {
      max-width: 40%;
      height: auto;
      max-height: 20vh;
      object-fit: contain;
      margin: 20px 0px;
      display: none; // to clean //
    }
    h4 {
      font-size: 1em !important;
    }

    a {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-transform: uppercase;
      color: $secondary;
      letter-spacing: 2px;
      text-align: center;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
