.btn {
  border-radius: 2px;
  transition: all 0.3s;
  &:hover {
    box-shadow: $primary-box-shadow-small;
    -webkit-box-shadow: $primary-box-shadow-small;
    -moz-box-shadow: $primary-box-shadow-small;
    cursor: pointer;
  }

  &.delete {
    border:1px solid $red!important;
    background-color:rgba(0,0,0,0)!important;
    color:$red!important;
    opacity:0.5;
    width:100%;
    &:hover {
      box-shadow:none!important;
      opacity:1;
      background-color:$red!important;
      color:white!important;
    }
  }
}

.btn-primary {
  background-color: $secondary !important;
  border: 1px solid $secondary !important;
  color:$textLight!important;
  font-weight: bold;

  &[disabled] {
    background-color: $primary !important;
    border: 1px solid $primary !important;

    &:hover {
      box-shadow: none;
      transform: 0;
      cursor: not-allowed;
    }
  }
}





.full-width {
  width:100%!important;
}