.texteffet {
  -webkit-mask-image: -webkit-radial-gradient(
      left top,
      ellipse cover,
      rgb(0, 0, 0) 0%,
      rgba(0, 0, 0, 0.1) 50%
    ),
    -webkit-radial-gradient(right bottom, ellipse cover, rgba(0, 0, 0, 0.1) 0%, rgb(
            0,
            0,
            0
          )
          30%),
    -webkit-radial-gradient(center bottom, ellipse cover, rgba(0, 0, 0, 0.1) 0%, rgba(
            0,
            0,
            0,
            0.7
          )
          10%, rgba(0, 0, 0, 0) 26%, rgba(0, 0, 0, 0) 100%),
    -webkit-radial-gradient(right top, ellipse cover, rgba(0, 0, 0, 0.1) 0%, rgba(
            0,
            0,
            0,
            0.7
          )
          20%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 100%);
  -webkit-mask-composite: xor;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  white-space: pre-line;
}

.editImageTextContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
}
