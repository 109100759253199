/** @format */

.modal {
  .accordion {
    .card {
      margin-bottom: 10px;
      &:first-of-type {
        background-clip: border-box;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
      }
    }
    .card-header {
      display: flex;
      align-items: center;
      background-color: $systemBG !important;
      padding: 0 !important;

      &.bg-transparent {
        background-color: transparent !important;
      }
      h5 {
        flex: 1;
        width: 100%;
        height: 100%;
        display: flex;
        height: 50px;
        padding: 0 !important;
        .btn {
          width: 100%;
          height: 100%;
        }
      }
    }

    .card-body {
      font-weight: 400;
      letter-spacing: 1px;
    }
  }
}

#tutorialModal {
  .tutorialButton {
    a {
      color: $secondary;

      &.active {
        color: white;
      }
    }
  }

  .card-header {
    color: white;
  }
}
