/** @format */

body {
  margin: 0;
  padding: 0;
  font-family: $corefont !important;
  color: $textMain;
}

.page,
body {
  background-color: $systemBG !important;
}

.bg-light {
  background-color: $systemBG-light !important;
}

.pageContainer {
  margin-top: 80px;
  padding: 10px;
}

h1,
h2,
h3,
h4,
h5 {
  text-transform: uppercase;
  font-weight: bold;
}

#root {
  min-height: 100vh;
}

button {
  text-transform: uppercase;
}

.error {
  color: $red;
}

a {
  &:hover {
    text-decoration: none;
  }
}

.text-light {
  color: $textMain !important;
  opacity: 0.7;
  font-size: 0.7em;
}

.text-main {
  color: $textMain;
}

.text-bigger {
  font-size: 1.8em;
}

.interactive {
  cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.modal-dialog {
  z-index: 1050;
}

.notifPane {
  position: fixed !important;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
}

.alert {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  #content-message {
    display: flex;
    flex: 3;
    flex-direction: column;
    width: 80%;
  }

  #answer {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin: 0px 5px;
    padding-right: 60px;

    .btn {
      margin: 0px 5px;

      &.btn-border {
        background-color: transparent !important;
        color: $secondary !important;
        border: solid 1px $secondary !important;

        &:hover {
          background-color: $secondary !important;
          color: white !important;
        }
      }
    }
  }
}

.half-width {
  width: 50%;
}

.bg-grey {
  background-color: $system !important;
}

.unlockbutton {
  transform: translateY(calc(-100% - 2px));
  margin-right: 30px;
}

.centerContent {
  align-self: center;
}
