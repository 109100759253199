/** @format */

.AtlantideFont {
  font-family: 'Barlow';
  color: #333333;
}
.navBarVersionNumber {
  color: #fff;
  font-size: 0.5em;
  padding-top: 10px;
  margin-right: 20px;
}
.BarlowFont {
  font-family: Barlow !important;
}
.breadcrumb {
  font-size: 0.7em;
}
.atlBreadcrumbBg {
  background-color: #00000000 !important;
}
.atlBreadcrumbItem a {
  color: #AE68F7CC;
}
.bgDarkBlue {
  background-color: #333333 !important;
}

.bgAqua {
  background-color: #AE68F7 !important;
}

.white {
  color: #fff !important;
}

.success {
  background-color: #74cd81 !important;
}
.warning {
  background-color: #fbefd2 !important;
}
.danger {
  background-color: #ffeef0 !important;
}

.LaunchScreen {
  display: table;
}

.component-controller {
  width: 100%;
  height: auto;
  padding: 1% 4% 0 4%;
  margin-top: 60px;
  z-index: 1;
}

.json {
  padding: 5% 0 5% 0;
}

.titleSide {
  padding-top: 1rem;
  margin-left: 10px;
  font-size: 17px;
}

.bar {
  width: 100%;
}

.buttonBack {
  text-align: center;
}

.buttonBackGroup {
  margin: 2% 25% 0 25%;
}

.right {
  width: 100%;
  float: right;
}

.LogoResp {
  float: left;
  width: 3rem;
  padding: 0;
}

.page {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transition: width 0.3s linear;
  transition: width 0.3s linear;
  width: calc(100% - 200px);
  background-color: #f3f4f6;
  min-height: 100vh;
}

#statistics {
  width: 100%;
}

.stat-accordion-toggle {
  text-transform: none;
  padding: 10px;
  color: #000000;
  cursor: pointer;
}

.big-stat {
  font-size: 25px;
  font-weight: 700;
}

.medium-stat {
  font-size: 20px;
  font-weight: 700;
}

.react-datepicker-popper {
  z-index: 5;
}
